import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function Header() {
  return (
    <>
      <div className="projects-3bg-default"
        style={{
          backgroundImage: "url(" + require("assets/img/ill/p31.svg") + ")",
        }}>
        <Container>
          <Row>
            <Col className="mr-auto ml-auto text-center" md="8">
              <h6 className="category text-muted">Our work</h6>
              <h2 className="title mb-5">We are versatile</h2>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Card
                className="card-background"
                style={{
                  backgroundImage:
                    "url(" +
                    require("assets/img/custom/mob.png") +
                    ")",
                }}
              >
                <CardBody className="text-left">
                  <div className="icon icon-shape bg-gradient-white shadow rounded-circle mb-3">
                    <i className="ni ni-mobile-button text-warning"></i>
                  </div>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h2">Mobile Apps</CardTitle>
                  </a>
                  <h6 className="desc text-white opacity-8">Android and iOS</h6>
                  {/* <Button
                    color="warning"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Watch Later
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card
                className="card-background"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/custom/sd.jpg") + ")",
                }}
              >
                <CardBody className="text-center">
                  <div className="icon icon-shape bg-gradient-white shadow rounded-circle mb-3">
                    <i className="ni ni-building text-danger"></i>
                  </div>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h2">Enterprise Apps</CardTitle>
                  </a>
                  <h6 className="desc text-white opacity-8">For SMEs</h6>
                  {/* <Button
                    color="danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Watch Later
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card
                className="card-background"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/custom/cloud-soft.jpg") + ")",
                }}
              >
                <CardBody className="text-right">
                  <div className="icon icon-shape bg-gradient-white shadow rounded-circle mb-3">
                    <i className="fa fa-cloud text-success"></i>
                  </div>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h2">Cloud Apps</CardTitle>
                  </a>
                  <h6 className="desc text-white opacity-8">SaaS</h6>
                  {/* <Button
                    color="success"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Watch Later
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Header;
