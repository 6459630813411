import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import MainNavbar from "components/common/MainNavbar";
import Footer from "components/common/Footer";
import Home from "components/home";
import Routes from "components/routing/Routes"
import ContactUs from "components/contactus"
import Error404 from "components/common/Error404"
import Foxerp from 'components/solutions/foxerp';
import Bizsync from 'components/solutions/bizsync'
import Custsol from 'components/solutions/custsol'
import Team from 'components/Team';


const App = () => {
  return (
    <BrowserRouter>
      <Fragment>
        {/* <MainNavbar type="transparent" /> */}
        <div className="wrapper">
          <Switch>
            {/* <Route path="/" exact render={(props) => <Home {...props} />} />
            <Route path="/home" exact render={(props) => <Home {...props} />} />

            <Route path="/foxerp" exact render={(props) => <Foxerp {...props} />} />
            <Route path="/custsol" exact render={(props) => <Custsol {...props} />} />
            <Route path="/bizsync" exact render={(props) => <Bizsync {...props} />} />

            <Route path="/team" exact render={(props) => <Team {...props} />} />

            <Route path="/contact-us" exact render={(props) => <ContactUsF {...props} />} />
            <Route path="/error" exact render={(props) => <Error404 {...props} />} />   
            <Redirect to="/error" /> */}

            <Route path="/" exact component={Home}/>
            <Route path="/home" exact component={Home} />

            <Route path="/foxerp" exact component={Foxerp} />
            <Route path="/custsol" exact component={Custsol} />
            <Route path="/bizsync" exact component={Bizsync} />

            <Route path="/team" exact component={Team} />

            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/error" exact component={Error404} />
            <Route component={Error404} />
          </Switch>
          <Footer />
        </div>
      </Fragment>
    </BrowserRouter>
  );
};

export default App;
