import React from "react";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";

// Core Components

function Header() {
  return (
    <>
      <div className="section section-hero section-shaped">
        <div className="page-header flex-start">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/ill/presentation_bg.png") + ")",
            }}
          ></div>
          <Container
            className="shape-container d-flex align-items-center py-lg"
            fluid
          >
            <div className="col px-0">
              <Row>
                <Col className="ml-5" lg="4">
                  {/* <div className="mt-9"></div> */}
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/brand/brand_c_600.png")}
                    style={{ width: "300px" }}
                  ></img>{" "}
                  {/* <Badge color="info">PRO</Badge> */}
                  <p className="lead">
                    <b>Fluidifying Human Technologies</b>
                    <br />
                    <br />
                    <small>Ride on an accelaration curve of e<sup>x</sup></small>
                    <br />
                    Helping you take ideas from Thoughts to Things
                    {/* Bold captions about us */}
                  </p>
                  {/* <div className="btn-wrapper mt-5">
                    <Button
                      className="btn-white btn-icon mb-3 mb-sm-0"
                      color="default"
                      href=""
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-shopping-cart"></i>
                      </span>
                      <span className="btn-inner--text">Contact Us</span>
                    </Button>
                  </div>
                  <div className="mt-5">
                    <small className="font-weight-bold mb-0 mr-2">
                      placeholder
                    </small>
                    
                  </div> */}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Header;
