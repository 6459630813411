import React from "react";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  Input,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function Header() {
  return (
    <>
      <header className="header-5bg-default"
        style={{
          backgroundImage: "url(" + require("assets/img/ill/p5.svg") + ")",
        }}>
        
        <div className="page-header">
          <Container>
            <Row className="align-items-center text-left">
              <Col lg="5" xs="12">
                <h1 className="display-3">
                  BizSync
                  <span className="text-primary">Develop Anything.</span>
                </h1>
                <p className="lead pb-4">
                BizSync, a full-fledged out of the box, highly customizable ERP application 
                which enables your organization to provide state of the art control over all 
                your business needs like procurement, manufacturing, inventory, finance, purchase, sales etc. 
                among many others.
                </p>
                {/* <Row className="row-input">
                  <Col sm="8" xs="12">
                    <Input
                      aria-label="Your email"
                      id="signupSrEmail"
                      name="email"
                      placeholder="Your email"
                      type="email"
                    ></Input>
                  </Col>
                  <Col className="pl-lg-0" sm="4" xs="12">
                    <Button block color="primary" type="submit">
                      More
                    </Button>
                  </Col>
                </Row> */}
              </Col>
              <Col className="pl-0" lg="7" xs="12">
                <img
                  alt="..."
                  className="ml-lg-5"
                  src={require("assets/img/ill/bg6-2.svg")}
                  width="100%"
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
}

export default Header;
