import React from "react";
import { Link, NavLink } from "react-router-dom";

// reactstrap components
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Core Components

function Footer() {
  const [searchFocus, setSearchFocus] = React.useState("");
  return (
    <>
      <div className="section section-shaped no-tilt">
        <div className="shape shape-style-1 shape-default">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="subscribe-line">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="6" sm="10">
                <div className="text-center">
                  <h4 className="title text-white">
                    Subscribe to our Newsletter
                  </h4>
                  <p className="description text-white">
                    Join our newsletter and get news in your inbox every week!
                    We hate spam too, so no worries about this.
                  </p>
                </div>
                <Form action="" className="mt-4" method="">
                  <Row>
                    <Col sm="8">
                      <FormGroup className={searchFocus}>
                        <InputGroup className="input-group-alternative mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search"
                            type="text"
                            onFocus={() => setSearchFocus("focused")}
                            onBlur={() => setSearchFocus("")}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <Button block color="info" type="button">
                        Subscribe
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="footer bg-transparent">
          <Container>
            <Row>
              <Col md="3">
                <UncontrolledDropdown className="mt-2">
                  <DropdownToggle
                    aria-expanded={false}
                    aria-haspopup={true}
                    caret
                    className="text-white"
                    color="link"
                    data-toggle="dropdown"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    role="button"
                  >
                    <i className="ni ni-pin-3"></i>
                    <span>India</span>
                  </DropdownToggle>
                  <DropdownMenu x-placement="bottom-start">
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span>Bangladesh</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span>Germany</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span>India</span>
                    </DropdownItem>

                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}>
                      <span>United States</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <br></br>
                <UncontrolledDropdown>
                  <DropdownToggle
                    aria-expanded={false}
                    aria-haspopup={true}
                    caret
                    className="text-white"
                    color="link"
                    data-toggle="dropdown"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    role="button"
                  >
                    <i className="ni ni-chat-round"></i>
                    <span>English</span>
                  </DropdownToggle>
                  <DropdownMenu x-placement="bottom-start">
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span>English</span>
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span>Bangla</span>
                    </DropdownItem>

                  </DropdownMenu>
                </UncontrolledDropdown>
                <img
                  alt="..."
                  className="img-fluid mt-5"
                  src={require("assets/img/brand/brand_m_600.png")}
                  style={{ width: "300px" }}
                ></img>{" "}
              </Col>
              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Solutions</h4>
                  <ul>
                    <li>
                    <NavItem>
                      <NavLink to="/bizsync" tag={Link}>
                        BizSync
                      </NavLink>
                    </NavItem>
                    </li>
                    <li>
                    <NavItem>
                      <NavLink to="/custsol" tag={Link}>
                        Customized Solutions
                      </NavLink>
                    </NavItem>
                    </li>
                    <li>
                    <NavItem>
                      <NavLink to="/custsol" tag={Link}>
                        LifeSync
                      </NavLink>
                    </NavItem>
                    </li>
                    <li>
                    <NavItem>
                      <NavLink to="/foxerp" tag={Link}>
                        Fox ERP
                      </NavLink>
                    </NavItem>
                    </li>
                    
                  </ul>
                </div>
              </Col>
              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Resources</h4>
                  <ul>
                    <NavItem>
                      <NavLink to="/contact-us" tag={Link}>
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/team" tag={Link}>
                        About Us
                      </NavLink>
                    </NavItem>

                  </ul>
                </div>
              </Col>
              
              <Col md="3" xs="6">
                <div className="column">
                  <h4 className="mt-3">Follow us:</h4>
                  <div className="btn-wrapper profile text-left">
                    <Button
                      color="twitter"
                      href=""
                      id="tooltip799285778"
                      size="sm"
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip799285778">
                      Follow us
                    </UncontrolledTooltip>
                    <Button
                      color="facebook"
                      href=""
                      id="tooltip33856223"
                      size="sm"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip33856223">
                      Like us
                    </UncontrolledTooltip>
                    <Button
                      color="slack"
                      href=""
                      id="tooltip521847749"
                      size="sm"
                      target="_blank"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip521847749">
                      Message Us
                    </UncontrolledTooltip>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  );
}

export default Footer;
