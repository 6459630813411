import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Feature() {
  return (
    <>
      <div
        className="section features-3 my-5"
        style={{
          backgroundImage: "url(" + require("assets/img/ill/p31.svg") + ")",
        }}
      >
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="8">
              <h2 className="title mb-5">
                Build the harmony<br />
                <span className="text-success">with our solutions</span>
              </h2>
              <p className="lead text-white">
                We’re constantly thriving to deliver excellence to our customers. Some of the portfolios where we
                achieved glory over years:
              </p>
            </Col>
          </Row>
          <Row className="row-grid mt-5">
            <Col lg="6">
              <div className="info info-horizontal bg-white">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="fa fa-desktop text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title text-info">Enterprise applications</h5>
                  <p className="text-dark">
                    Our  team is involved in rendering wide range of IT and R&D services.
                    We offer high quality services that match the expectations of our customers.
                    Best possible solutions keeping in mind the clients' strategic business interests and risks
                    involved. Highly reliable, effective and can be implemented as per the specifications
                    provided by the clients, with many others we are specialized in:
                    <ul>
                      <li>Hospitality & Tourism Management</li>
                      <li>Pharmaceutical Retailing</li>
                      <li>Consumer products Retailing</li>
                      <li>Small & Mid Scale Manufacturing</li>
                      <li>Micro Financials</li>
                    </ul>
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="info info-horizontal bg-white">
                <div className="icon icon-shape icon-shape-success rounded-circle text-white">
                  <i className="fa fa-snowflake text-success"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title text-success">Foxbird Web</h5>
                  <p className="text-primary">
                    The Web Designing Wing of Foxbird - A team of highly talented professionals dedicatedly
                    focused to over-satisfy all your www needs equipped with latest cutting edge technologies
                    and out of the box ideas. Along with many others we provide the following web solutions:
                    <ol>
                      <li>Web Design & Development</li>
                      <li>Web Maintenence & Hosting</li>
                      <li>Branding Solutions</li>
                      <li>Web Marketing & Promotions</li>
                    </ol>
                  </p>
                  {/* <a
                    className="text-success"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="row-grid">
            <Col lg="6">
              <div className="info info-horizontal bg-white">
                <div className="icon icon-shape icon-shape-warning rounded-circle text-white">
                  <i className="fa fa-heart text-warning"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title text-warning">Modern Healthcare - LifeSync</h5>
                  <p className="text-dark">
                    Improve efficiency and workflow in revenue cycle and technology operations with services
                    that impact cost management and cash optimization for hospitals and other large health
                    care organizations. It also includes complete solutions for pharmacy and pathology
                  </p>
                  {/* <a
                    className="text-warning"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="info info-horizontal bg-white">
                <div className="icon icon-shape icon-shape-danger rounded-circle text-white">
                  <i className="fa fa-handshake text-danger"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title text-danger">Consultancy</h5>
                  <p className="text-dark">
                    Our Fox Management Consulting(FMC) helps to provide the best possible
                    support to our clients through our unique spectrum of management consulting services given below:
                    <ol>
                      <li>Enterprise Architecture Consulting</li>
                      <li>Cloud Services</li>
                      <li>Customer Relationship Management (CRM)</li>
                    </ol>
                  </p>
                  {/* <a
                    className="text-danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
}

export default Feature;
