import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import Header from "components/home/Header.js";
// Sections for this page
import Info from "components/home/Info.js";
import Testimonials from "components/home/Testimonials";
import MainNavbar from "components/common/MainNavbar";



function Home() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
    return function cleanup() {
      document.body.classList.remove("presentation-page");
    };
  });
  return (
    <>
      <MainNavbar type="transparent" />
      <Header />
      <Info />
      <Testimonials />
    </>
  );
}

export default Home;
