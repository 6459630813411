import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Header() {
  return (
    <>
      <section className="section-basic-components bg-default"
        style={{
          backgroundImage: "url(" + require("assets/img/ill/p5.svg") + ")",
        }}>
        <Container>
          <Row>
            <Col className="mb-md-5" lg="5" md="10">
              <h1 className="display-3">
                Fox ERP
                <span className="text-primary">
                Synchronizing Your Business Evolution
                </span>
              </h1>
              <p className="lead">
              FoxERP, a full-fledged, software as a service (SaaS) as well as on premises deployable, 
              highly customizable ERP application which enables your organization to provide state of the art 
              control over all your business needs like procurement, manufacturing, inventory, finance, purchase, 
              sales etc. among many others.
              </p>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="table-img"
                  src={require("assets/img/presentation-page/table.png")}
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={require("assets/img/presentation-page/card-btn.png")}
                ></img>
                <img
                  alt="..."
                  className="coloured-card-img"
                  src={require("assets/img/presentation-page/card-orange.png")}
                ></img>
                <img
                  alt="..."
                  className="linkedin-btn-img"
                  src={require("assets/img/presentation-page/slack-btn.png")}
                ></img>
                <img
                  alt="..."
                  className="w-100"
                  src={require("assets/img/ill/example-3.svg")}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Header;
