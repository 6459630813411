import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Core Components

function Info() {
  return (
    <>
      <section className="section-info bg-default"
        style={{
          backgroundImage: "url(" + require("assets/img/ill/p31.svg") + ")",
        }}>
        <Container>
          <Row>
            <Col className="ml-4" lg="8">
              <p className="lead">
                <b>The Factors</b><br></br>
                Like the software world, where the non-functional
                requirements are the pseudo strengths,
                we do possess some pseudo fundamentals
              </p>
              <br />
              <br />
              <br />
            </Col>
          </Row>

          <Row>
            <Col lg="4" md="6">
              <div className="info text-left">
                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                  <i className="ni ni-user-run"></i>
                </div>
                <h6 className="info-title text-uppercase text-primary pl-0">
                  The team
                </h6>
                <p className="description opacity-8">
                  They do eat and breathe, but they don’t keep their mind in the confined pot of conventional ways.
                  A team of highly qualified and focused professionals are working relentlessly innovating new edges
                  and making customers' dreams come more than true.
                </p>
                <Link className="text-primary" to="/team">
                  More about us{" "}
                  <i className="ni ni-bold-right text-primary"></i>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="info text-left" style={{ marginTop: "-50px" }}>
                <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
                  <i className="ni ni-key-25"></i>
                </div>
                <h6 className="info-title text-uppercase text-success pl-0">
                  Integrity of Morale
                </h6>
                <p className="description opacity-8">
                  Along with technical excellence, the keys of our success rely on our ethics based on
                  human moral values. Valuing morale brings us positive consumer support, business
                  awareness & recognition and employee motivation.
                </p>
                {/* <Link className="text-primary" to="/sections">
                  Learn about our products{" "}
                  <i className="ni ni-bold-right text-primary"></i>
                </Link> */}
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="info text-left">
                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                  <i className="ni ni-book-bookmark"></i>
                </div>
                <h6 className="info-title text-uppercase text-warning pl-0">
                  The Foundation
                </h6>
                <p className="description opacity-8">
                  Smarter software architectures are the muscles behind Equinox solutions for industries,
                  which help in transforming the way industries, and the planet, works. Use the list of
                  enterprise solutions from our offers that can help you embrace the future of work and
                  reshape your enterprise for what lies ahead.
                </p>
                {/* <a
                  className="text-primary"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Check our documentation{" "}
                  <i className="ni ni-bold-right text-primary"></i>
                </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Info;
