import React, { Fragment, useEffect } from 'react';
import Header from "components/solutions/custsol/Header";
import Feature from 'components/solutions/custsol/Feature';
import MainNavbar from 'components/common/MainNavbar';

const Page = () => {
  React.useEffect(() => {
    document.body.classList.add("sections-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      document.getElementById(href)
    ) {
      document.getElementById(href).scrollIntoView();
    }
    return function cleanup() {
      document.body.classList.remove("sections-page");
    };
  });
  return (
    <>
      <MainNavbar type="transparent" />
      <Header />
      <Feature />
    </>
  )
}

export default Page;